import { useParams } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import SimpleLayout from '../components/customPage/SimpleLayout';

const CustomPage = () => {
  const { id } = useParams();
  const { data } = useFetch(`/page/${id}`);
  return (
    <>
      {data && (
        <SimpleLayout
          title={data.title}
          content={data.content}
        />
      )}
    </>
  );
};

export default CustomPage;
