import { useNavigate } from 'react-router-dom';
import Input from '../Input';
import './auth.scss';

const ForgetPasswordForm = () => {
  const nav = useNavigate();
  // todo reset password api
  return (
    <form className="auth-form reset-password">
      <p>
        Entered your registered email. The system will email
        you your account information shortly.
      </p>
      <Input type="text" placeholder="Enter your email" />
      <ul style={{ margin: '50px 0px' }}>
        <li>
          If you can&apos;t remember the e-mail address you
          registered with, please contact us.
        </li>
        <li>Has your email changed?</li>
        <li style={{ listStyle: 'none' }}>
          If you no longer use the email address associated
          with your 2025 APSAVD account, you may contact us
          for help restoring access to your account.
        </li>
      </ul>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <button
          className="auth-button"
          style={{ minWidth: '130px', margin: '0 10px' }}
          onClick={() => {
            nav('/login');
          }}
        >
          Back to sign in
        </button>
        <button
          className="auth-button"
          style={{ minWidth: '130px', margin: '0 10px' }}
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default ForgetPasswordForm;
