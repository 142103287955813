import './footer.scss';

const Footer = () => {
  return (
    <>
      <footer>
        <section className="container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.0164136566154!2d121.55831101145614!3d25.033517038217425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442abb61ab3d33f%3A0xab0d0f7ac395bff4!2z5Y-w5YyX5ZyL6Zqb5pyD6K2w5Lit5b-DKFRJQ0Mp!5e0!3m2!1szh-TW!2stw!4v1727769868031!5m2!1szh-TW!2stw"
            height="360"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
          <div className="home-contact">
            <div className="fz-30 fw-800 home-contact-title">
              CONTACT INFORMATION
            </div>
            <p>
              <b>2025 APSAVD Congress Secretariat</b>
            </p>
            <p>Mr. Toby Hung</p>
            <p>Email: apsavd2025@gmail.com</p>
            <p>Tel: +886-2-2509-5759 ext.203</p>
            <p>Website: www.apsavd2025.tw</p>
          </div>
        </section>
      </footer>
      <div className="copyrights fz-14">
        Copyrights @ APSAVD 2025. All Rights Reserved.
      </div>
    </>
  );
};

export default Footer;
