import { useEffect, useState } from 'react';
import { useRequest } from '../../context/RequestContext';
import { BiEdit, BiTrash, BiUpload } from 'react-icons/bi';
import { NavLink, useNavigate } from 'react-router-dom';
import { AiOutlineFileAdd } from 'react-icons/ai';
import useFetch from '../../hooks/useFetch';

import '../../assets/scss/submission.scss';

const SubmissionList = () => {
  const nav = useNavigate();
  const {
    data,
    loading,
    fetchData,
    error: getError,
  } = useFetch('/submission');
  // const { request, loading } = useRequest();
  // const [data, setData] = useState([]);
  // const fetchSubmissionList = async () => {
  //   const response = await request(
  //     '/custom/v1/submission',
  //     {
  //       method: 'GET',
  //     }
  //   );

  //   if (response?.success) {
  //     setData(response?.data);
  //   } else {
  //     setData([]);
  //   }
  // };

  // const handleUpdateSubmission = async ({
  //   item,
  //   status,
  // }) => {
  //   if (item.id) {
  //     const response = await request(
  //       '/custom/v1/update-submission-status',
  //       {
  //         method: 'POST',
  //         body: JSON.stringify({
  //           id: item.id,
  //           status: status,
  //         }),
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     );

  //     if (response?.success) {
  //       fetchSubmissionList();
  //     }
  //   }
  // };

  // useEffect(() => {
  //   fetchSubmissionList();
  // }, []);

  const handleChangeStatus = () => {};

  return (
    <>
      <div className="container">
        <div className="page-title fz-28">
          Abstract Submission
        </div>
        <div className="form-container">
          <div className="fz-20 fw-800 form-title">
            Submission List
          </div>
          <table className="submission-list">
            <NavLink
              to="/submission/create"
              className="submission-new-btn"
            >
              New Submission
            </NavLink>
            <thead>
              <tr>
                <th>Ref. No.</th>
                <th>Abstract Title</th>
                <th>Presenting Author</th>
                <th>Corresponding Author</th>
                <th>Last Edited</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.map((item, idx) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>
                    {item.abstract_title.length > 50
                      ? `${item.abstract_title.slice(0, 50)}...`
                      : item.abstract_title}
                  </td>
                  <td>{item.presenting_author}</td>
                  <td>{item.corresponding_author}</td>
                  <td>{item.updated_at}</td>
                  <td data-status={item.status}>
                    {item.status}
                  </td>
                  <td>
                    <div className="edit-group">
                      {item.status !== 'Submitted' && (
                        <>
                          <button
                            className="submission-btn fw-800"
                            onClick={() =>
                              handleChangeStatus({
                                id: item.id,
                                status: 'Submitted',
                              })
                            }
                          >
                            Submit
                          </button>
                          <NavLink
                            to={`/submission/${item.id}`}
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/images/submission-icon-edit.svg`}
                              alt="submission-icon-edit"
                            />
                          </NavLink>
                        </>
                      )}
                      <NavLink
                        onClick={() =>
                          handleChangeStatus({
                            id: item.id,
                            status: 'Canceled',
                          })
                        }
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/images/submission-icon-delete.svg`}
                          alt="submission-icon-delete"
                        />
                      </NavLink>
                    </div>
                  </td>
                </tr>
              ))}
              {!loading && data?.data?.length === 0 && (
                <tr>
                  <td
                    colSpan={8}
                    className="not-found text-center"
                  >
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SubmissionList;
