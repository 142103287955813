import {
  FaChevronLeft,
  FaChevronRight,
} from 'react-icons/fa';

const Pagination = ({
  totalPages,
  onPageChange,
  currentPage,
}) => {
  return (
    <div className="pagination">
      <div
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="pagination-button arrow"
      >
        <FaChevronLeft />
      </div>
      {Array.from({ length: totalPages }).map(
        (_, index) => (
          <div
            key={index}
            onClick={() => onPageChange(index + 1)}
            disabled={
              +currentPage === index + 1 &&
              +currentPage !== 1
            }
            className={`pagination-button ${
              +currentPage === index + 1 ? 'active' : ''
            }`}
          >
            {index + 1}
          </div>
        )
      )}
      <div
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="pagination-button arrow"
      >
        <FaChevronRight />
      </div>
    </div>
  );
};

export default Pagination;
