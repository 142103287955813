import { useState } from 'react';
import RegisterForm from '../components/auth/RegisterForm';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const nav = useNavigate();
  return (
    <div
      className="container"
      style={{
        flexGrow: 1,
        display: 'flex',
      }}
    >
      {!isSuccess ? (
        <RegisterForm onSuccess={setIsSuccess} />
      ) : (
        <main
          style={{
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'center',
          }}
        >
          <div
            className="fz-30"
            style={{
              margin: 'auto',
              textAlign: 'center',
            }}
          >
            Activate Your Account
          </div>
          <ul style={{ width: '80%', margin: '0 auto' }}>
            <li style={{ margin: '30px 0' }}>
              A system verification email has been sent to
              your email address, please check the mailbox
              and activate your account.
            </li>
            <li style={{ margin: '30px 0' }}>
              Please note that future 2025 APSAVD
              notifications will be sent to your email
              address.
            </li>
            <li style={{ margin: '30px 0' }}>
              For inquiries, please contact 2025 APSAVD
              Secretariat:
            </li>
            <div>
              <p>2025 APSAVD Congress Secretariat</p>
              <p>Mr. Toby Hung</p>
              <p>Email: apsavd2025@gmail.com</p>
              <p>Tel: +886-2-2509-5759 ext.203</p>
              <p>Website: https://apsavd2025.tw</p>
            </div>
          </ul>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '40px',
            }}
          >
            <button
              className="primary-button-style"
              onClick={() => nav('/login')}
            >
              Back to Sign In
            </button>
          </div>
        </main>
      )}
    </div>
  );
};

export default Register;
