import { createContext, useContext, useState } from 'react';

const RequestContext = createContext({
  request: async () => {},
  loading: false,
  error: '',
});

export const useRequest = () => {
  return useContext(RequestContext);
};

export const RequestProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = async (url, options) => {
    setLoading(true);
    setError(null);

    if (localStorage.getItem('apsavd_user_token')) {
      if (!options['headers']) {
        options['headers'] = {};
      }
      options['headers']['Authorization'] =
        `Bearer ${localStorage.getItem(
          'apsavd_user_token'
        )}`;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URI}${url}`,
        options
      );
      if (!response.ok) {
        const res = await response.json();
        return {
          success: false,
          code: response.status,
          message: res.message,
        };
      }
      const data = await response.json();
      return { success: true, data };
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <RequestContext.Provider
      value={{ request, loading, error }}
    >
      {children}
    </RequestContext.Provider>
  );
};
