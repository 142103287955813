import {
  NavLink,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import './header.scss';
import {
  Fragment,
  useEffect,
  useState,
  useMemo,
  useContext,
} from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { TfiClose } from 'react-icons/tfi';
import { AuthContext } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const MemberMenuData = () => ({
  ID: 'menu-item-member',
  title: 'MEMBER',
  children: [
    {
      ID: 'member-info',
      title: 'MEMBERSHIP INFORMATION',
      url: '/membership',
    },
    {
      ID: 'member-logout',
      title: 'LOG OUT',
      url: '',
    },
  ],
});

const useHandleLogout = () => {
  const { logout } = useContext(AuthContext);
  const nav = useNavigate();
  const handleLogout = () => {
    logout();
    Swal.fire({
      text: 'You have successfully logged out.',
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'swal-btn swal-confirm',
      },
    }).then(() => {
      nav('/');
    });
  };

  return handleLogout;
};

const DesktopMenber = () => {
  const memberMenu = MemberMenuData();
  const { isLogin, logout } = useContext(AuthContext);
  const nav = useNavigate();
  const location = useLocation();
  const isActive = location.pathname === '/membership';
  const handleLogout = useHandleLogout();

  return (
    <>
      {isLogin ? (
        <div
          className={`menu-item menu-item-member ${
            isActive ? 'active' : ''
          }`}
          onMouseEnter={e => {
            const img =
              e.currentTarget.querySelector('img');
            if (img) {
              img.src = `${process.env.PUBLIC_URL}/images/member-hover.png`;
            }
          }}
          onMouseLeave={e => {
            const img =
              e.currentTarget.querySelector('img');
            if (img) {
              img.src = `${process.env.PUBLIC_URL}/images/member.png`;
            }
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/member.png`}
            alt="Member"
            className="menu-icon"
          />
          <div className="sub-menu">
            {memberMenu.children.map(child => (
              <div
                className="item"
                key={child.ID}
                onClick={() => {
                  if (child.ID === 'member-logout') {
                    handleLogout();
                  } else {
                    nav(child.url);
                  }
                }}
              >
                {child.title}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="menu-item-member-login">
          <NavLink to={'/sign-in'}>LOG IN</NavLink>
        </div>
      )}
    </>
  );
};

const DesktopMenu = ({ items }) => {
  const nav = useNavigate();
  return (
    <>
      {items?.map(item => (
        <div
          className={`menu-item ${
            item.children.length === 0
              ? item.url === location.pathname && 'active'
              : item.children.find(
                  child => child.url === location.pathname
                ) && 'active'
          }`}
          key={item.ID}
          onClick={() =>
            item.children.length === 0 && nav(item.url)
          }
        >
          {item.title}
          {item.children.length > 0 && (
            <Fragment>
              <BiChevronDown className="arrow-icon" />
              <div className="sub-menu">
                {item.children.map(child => (
                  <div
                    className="item"
                    key={child.ID}
                    onClick={() => {
                      nav(child.url);
                    }}
                  >
                    {child.title}
                  </div>
                ))}
              </div>
            </Fragment>
          )}
        </div>
      ))}
      {/* <DesktopMenber /> */}
    </>
  );
};

const MobileMenu = ({ items, onToggle }) => {
  const nav = useNavigate();
  const location = useLocation();
  const [menuItems, setMenuItems] = useState([]);
  const memberMenu = useMemo(() => MemberMenuData(), []);
  const { isLogin, logout } = useContext(AuthContext);
  const handleLogout = useHandleLogout();

  useEffect(() => {
    const initializeMenu = () => {
      let newItems = items.map(item => ({
        ...item,
        isExtened: item.children.some(
          child =>
            child.url === location.pathname &&
            location.pathname !== '/'
        ),
      }));

      if (isLogin) {
        newItems = [
          ...newItems,
          ...memberMenu.children.map(child => ({
            ...child,
            isExtened: false,
          })),
        ];
      }
      // else {
      //   newItems.push({
      //     ID: 'login',
      //     title: 'LOG IN',
      //     url: '/sign-in',
      //     children: [],
      //   });
      // }

      setMenuItems(newItems);
    };
    initializeMenu();
  }, [items, location.pathname, isLogin]);

  const toggleSubMenu = itemId => {
    setMenuItems(prev =>
      prev.map(item => ({
        ...item,
        isExtened:
          item.ID === itemId ? !item.isExtened : false,
      }))
    );
  };

  const handleToggleMenu = () => {
    setMenuItems(prev =>
      prev.map(item => ({
        ...item,
        isExtened: false,
      }))
    );
    onToggle();
  };

  return (
    <div className="menu-mask" onClick={handleToggleMenu}>
      <div
        className="menu-item-mobile"
        onClick={e => e.stopPropagation()}
      >
        <div className="menu-item-mobile-header">
          <Link to="/" onClick={handleToggleMenu}>
            <img
              className="logo"
              src={`${process.env.PUBLIC_URL}/logo192.png`}
              alt="Logo"
            />
          </Link>
          <div
            className="close-btn"
            onClick={handleToggleMenu}
          >
            <TfiClose />
          </div>
        </div>

        {menuItems.map(item => (
          <div
            key={item.ID || item.title}
            className={`item-mobile ${
              item.title === 'MEMBERSHIP INFORMATION' ||
              item.title === 'LOG OUT' ||
              item.title === 'LOG IN'
                ? 'custom-item'
                : ''
            }`}
          >
            <div
              className={`main-item ${
                item.isExtened ? 'extended' : ''
              }`}
              onClick={() => {
                if (item.ID === 'member-logout') {
                  handleLogout();
                  setTimeout(() => {
                    handleToggleMenu();
                  }, 500);
                } else if (item.children?.length) {
                  toggleSubMenu(item.ID);
                } else {
                  nav(item.url);
                  handleToggleMenu();
                }
              }}
            >
              {item.title}
              {item.children?.length > 0 && (
                <BiChevronDown
                  className={`arrow-icon ${
                    item.isExtened ? 'rotated' : ''
                  }`}
                />
              )}
            </div>

            {item.isExtened &&
              item.children?.map((child, i) => (
                <div
                  key={i}
                  className={`sub-item ${
                    child.url === location.pathname
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => {
                    nav(child.url);
                    handleToggleMenu();
                    setMenuItems(prev =>
                      prev.map(menuItem => ({
                        ...menuItem,
                        isExtened: false,
                      }))
                    );
                  }}
                >
                  {child.title}
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const Header = () => {
  const [styles, setStyles] = useState({});
  const updateStyles = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth > 1480 && screenWidth <= 1920) {
      setStyles({
        marginLeft: `${405 - (1920 - screenWidth) * 0.5}px`,
      });
    } else if (screenWidth > 1920 && screenWidth <= 2240) {
      setStyles({
        marginLeft: `${410 - (1920 - screenWidth) * 0.2}px`,
      });
    } else if (screenWidth > 2240) {
      setStyles({
        marginLeft: `${420 - (1920 - screenWidth) * 0.2}px`,
      });
    }
  };

  useEffect(() => {
    updateStyles();
    window.addEventListener('resize', updateStyles);
    return () =>
      window.removeEventListener('resize', updateStyles);
  }, []);

  const { data: items, loading } = useFetch('/menu', {
    method: 'GET',
  });
  const [isMobileMenuOpen, setIsMobileMenuOpen] =
    useState(false);

  const handleToggleMobileMenu = () => {
    setIsMobileMenuOpen(prevState => !prevState);
  };

  const closeMenu = () => {
    if (window.innerWidth >= 768) {
      setIsMobileMenuOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', closeMenu);

    return () =>
      window.removeEventListener('resize', closeMenu);
  }, []);

  if (loading)
    return (
      <nav className="menu-container" style={styles}>
        <div className="menu-header"></div>
      </nav>
    );
  return (
    <>
      <div className="bg-w">
        <nav className="menu-container" style={styles}>
          <div className="menu-header">
            <Link
              to="/"
              onClick={() => {
                if (isMobileMenuOpen)
                  handleToggleMobileMenu();
              }}
            >
              <img
                className="logo"
                src={`${process.env.PUBLIC_URL}/logo192.png`}
                alt="Logo"
              />
            </Link>
            <i
              className="icon"
              onClick={() => handleToggleMobileMenu()}
            />
            <DesktopMenu items={items} />
            {isMobileMenuOpen && (
              <MobileMenu
                items={items}
                onToggle={handleToggleMobileMenu}
              />
            )}
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
