import { useEffect } from 'react';
import '../components/auth/auth.scss';
import LoginForm from '../components/auth/LoginForm';
import { useAuth } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { FadeLoader } from 'react-spinners';

const Login = () => {
  const { handleValidate, loading } = useAuth();
  const nav = useNavigate();
  useEffect(() => {
    handleValidate({
      onSuccess: () => {
        nav('/');
      },
      onError: () => {
        // alert('');
      },
    });
  }, []);
  return (
    <div
      className="container"
      style={{
        flexGrow: 1,
        display: 'flex',
      }}
    >
      {!loading ? (
        <LoginForm />
      ) : (
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FadeLoader color="#6495ed" size={200} />
        </div>
      )}
    </div>
  );
};

export default Login;
