import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import './fileDropzone.scss';
import FileThumbnail from './FileThumbnail';
import Swal from 'sweetalert2';

const FileDropzone = ({
  acceptType = {},
  maxSize = 1,
  setFieldValue,
  file,
  field,
}) => {
  const acceptString = Object.keys(acceptType).join(',');
  const inputRef = useRef(null);
  const [currentFile, setCurrentFile] = useState(null);
  const onDrop = useCallback(files => {
    setFieldValue(field, files[0]);
    setCurrentFile(files[0]);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: acceptType,
    onDrop,
    onDropRejected: () => {
      Swal.fire({
        text: `Maximum file size: ${maxSize}MB / file format: ${acceptString}`,
        customClass: {
          confirmButton: 'swal-btn',
          showClass: { popup: '' },
          hideClass: { popup: '' },
        },
      });
    },
    maxSize: maxSize * 1024 * 1024,
  });

  useEffect(() => {
    if (file) {
      setCurrentFile(file);
    }
  }, [file]);

  return (
    <>
      {currentFile && (
        <>
          <p className="fz-12 color-gray fw-800 mt-10">
            Abstract File
          </p>
          <div className="reupload-container">
            <div>
              <FileThumbnail
                file={currentFile}
                isAllowedReupload={true}
              />
            </div>
            <div>
              <span
                className="reupload-text hover"
                onClick={() => {
                  inputRef.current.click();
                }}
              >
                Reupload
              </span>
              <br />
              <span className="fz-12 color-gray">
                {`Maximum file size: ${maxSize}MB / file format: ${acceptString}`}
              </span>
              <input
                type="file"
                style={{ visibility: 'hidden' }}
                ref={inputRef}
                onChange={e => {
                  const file = e.target.files[0];
                  setFieldValue('abstractFile', file);
                  setCurrentFile(file);
                }}
                accept={acceptString}
              />
            </div>
          </div>
        </>
      )}
      {!currentFile && (
        <>
          <div
            {...getRootProps({
              className: `dropzone-container ${isDragActive ? 'dropzone-active' : ''}`,
            })}
          >
            <input {...getInputProps()} />

            <div className="dropzone-body">
              <img
                className="dropzone-icon"
                // eslint-disable-next-line no-undef
                src={`${process.env.PUBLIC_URL}/images/file-dropzone-icon.svg`}
              />
              <p className="dropzone-text mt-10 color-blue">
                {isDragActive
                  ? 'Please Drag and drop your images here'
                  : 'Choose a file or drag & drop it here.'}
              </p>
            </div>
          </div>
          <div className={`fz-12 color-gray`}>
            {`Maximum file size: ${maxSize}MB / file format: ${acceptString}`}
          </div>
        </>
      )}
    </>
  );
};

export default FileDropzone;
