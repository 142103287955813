import ForgetPasswordForm from '../components/auth/ForgetPasswordForm';

const ForgetPassword = () => {
  return (
    <div
      className="container"
      style={{
        flexGrow: 1,
        display: 'flex',
      }}
    >
      <ForgetPasswordForm />
    </div>
  );
};

export default ForgetPassword;
