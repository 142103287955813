import {
  createContext,
  useContext,
  useReducer,
} from 'react';
import { useRequest } from './RequestContext';

const initialState = {
  token: null,
  isLogin: false,
  loading: false,
  success: false,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        token: action.payload.token,
        isLogin: true,
      };
    case 'LOGOUT':
      return {
        ...state,
        token: null,
        isLogin: false,
      };
    case 'VALIDATE':
      return {
        ...state,
        token: action.payload.token,
        isLogin: true,
      };
    case 'REGISTER_SUCCESS':
      return {
        ...state,
        success: true,
      };
    case 'INIT':
      return {
        ...state,
        success: false,
      };
    default:
      return state;
  }
};

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const { request, loading } = useRequest();
  const [state, dispatch] = useReducer(
    authReducer,
    initialState
  );

  const handleLogin = async ({
    email = '',
    password = '',
  }) => {
    const result = await request('/jwt-auth/v1/token', {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: email,
        password,
      }),
      method: 'POST',
    });

    if (result.success) {
      dispatch({
        type: 'LOGIN',
        payload: { token: result.data.token },
      });
      localStorage.setItem(
        'apsavd_user_token',
        result.data.token
      );
    } else {
      let msg;
      switch (result.code) {
        case 500:
          msg =
            'Something went wrong on the server. Please try again later or contact support if the issue persists.';
          break;
        case 403:
        case 400:
        case 404:
          msg =
            result.message ||
            'Invalid email or password. Please try again.';
          break;
        default:
          msg =
            'An error occurred. Please try again later.';
      }
      alert(msg);
    }
  };

  const handleLogout = () => {
    dispatch({ type: 'LOGOUT' });
    localStorage.removeItem('apsavd_user_token');
  };

  const handleValidate = async ({
    onSuccess = () => {},
    onError = () => {},
  }) => {
    const userToken = localStorage.getItem(
      'apsavd_user_token'
    );

    if (userToken) {
      const result = await request(
        '/jwt-auth/v1/token/validate',
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          method: 'POST',
        }
      );

      if (result.success) {
        dispatch({
          type: 'VALIDATE',
          payload: { token: userToken },
        });
        onSuccess();
      } else {
        handleLogout();
        onError();
      }
    }
  };

  const handleRegister = async body => {
    const result = await request('/custom/v1/register/', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(body),
    });

    if (result.success) {
      dispatch({ type: 'REGISTER_SUCCESS' });
    } else {
      alert('The email is already registered.');
    }
  };

  const resetStatus = () => {
    dispatch({ type: 'INIT' });
  };

  return (
    <AuthContext.Provider
      value={{
        handleLogin,
        handleLogout,
        handleValidate,
        handleRegister,
        resetStatus,
        token: state.token,
        isLogin: state.isLogin,
        loading,
        success: state.success,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
