/* eslint-disable no-undef */
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';

const useFetch = (url, options = {}, isDelay = false) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token, checkTokenValidity } =
    useContext(AuthContext);

  const fetchData = async () => {
    try {
      checkTokenValidity();
      if (token) {
        options = {
          ...options,
          headers: {
            ...options.headers,
            Authorization: `Bearer ${token}`,
          },
        };
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URI}${process.env.REACT_APP_REST_API_URI}${url}`,
        {
          ...options,
        }
      );

      const result = await response.json();

      if (!response.success) {
        setError(result.message);
      }
      setData(result);
    } catch (err) {
      setError(err.meaage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isDelay) {
      fetchData();
    }
  }, [url]);

  const handleReset = () => {
    setData(null);
    setError(null);
    setLoading(false);
  };

  return { data, loading, error, fetchData, handleReset };
};

export default useFetch;
