import useFetch from '../../hooks/useFetch';
import './sponsors.scss';
import React from 'react';
// import CorporationLogo from '../../components/CorporationLogo';

const Sponsors = () => {
  const { data } = useFetch('/corporation/logo');
  console.log(data);
  return (
    <section className="container sponsors mt-40 mb-40">
      <div className="sponsors-title">
        <span className="fz-30 fw-800 mx-10">
          OUR SPONSORS
        </span>
        <div className="line arrow-line-g"></div>
      </div>
      {/* <CorporationLogo /> */}
      <div dangerouslySetInnerHTML={{ __html: data }}></div>
    </section>
  );
};

export default Sponsors;
