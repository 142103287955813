import { useEffect, useState } from 'react';
import SubmissionForm from '../../components/submission/SubmissionForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useRequest } from '../../context/RequestContext';

const SubmissionEdit = () => {
  const nav = useNavigate();
  const { id } = useParams();
  const { request, loading } = useRequest();
  const [data, setData] = useState({});
  const fetchSubmission = async () => {
    const response = await request(
      `/custom/v1/submission/${id}`,
      {
        method: 'GET',
      }
    );

    if (response?.success) {
      setData(response.data);
    } else {
      nav('/');
    }
  };
  useEffect(() => {
    fetchSubmission();
  }, []);

  return (
    <div
      className="container"
      style={{
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <SubmissionForm
        data={data}
        onRefresh={() => fetchSubmission()}
      />
    </div>
  );
};

export default SubmissionEdit;
