import './keyDate.scss';
import React from 'react';
import Slider from 'react-slick';
import moment from 'moment';
import {
  FaChevronLeft,
  FaChevronRight,
} from 'react-icons/fa';

const PrevArrow = ({ onClick }) => (
  <FaChevronLeft
    onClick={onClick}
    color="#fff"
    className="prev-arrow"
  />
);

const NextArrow = ({ onClick }) => (
  <FaChevronRight
    onClick={onClick}
    color="#fff"
    className="next-arrow"
  />
);

const KeyDates = ({ keyDates }) => {
  const sliderSettings = {
    infinite: true,
    slidesToShow: 3,
    rows: 1,
    slidesPerRow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          rows: 1,
          slidesPerRow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          rows: 2,
          slidesPerRow: 1,
        },
      },
    ],
  };

  return (
    <div className="key-date pt-20">
      <p className="fz-30 fw-800 mx-10">KEY DATES</p>
      <div className="arrow-line arrow-line-l-100 arrow-line-w"></div>
      <Slider className="slider" {...sliderSettings}>
        {keyDates?.map((data, idx) => (
          <div key={idx}>
            <div className="slider-title-box">
              <div className="slider-title">
                <span className="date-dd">
                  {moment(data.date).format('DD')}
                </span>
                <span className="date-mmm">
                  {moment(data.date).format('MMM')}
                </span>
              </div>
              <div className="slider-des fz-18">
                {data.label}
              </div>
            </div>
            {data.hyperlink && (
              <i
                className="calendor-icon"
                onClick={() =>
                  window.open(data.hyperlink, '_blank')
                }
              />
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default KeyDates;
