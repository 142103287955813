import './welcomeMessage.scss';
const WelcomeMessage = () => {
  return (
    <section className="welcome-message">
      <div className="welcome-message-title">
        <p className="fz-30 fw-800">WELCOME MESSAGE</p>
        <div className="line"></div>
        <div className="welcome-message-img">
          <img
            src={`${process.env.PUBLIC_URL}/images/home-img-1.png`}
            alt="Welcome Image"
          />
        </div>
      </div>
      <div className="welcome-message-text">
        Dear Esteemed Colleagues, Distinguished Guests, and
        Friends,
        <br /> It is with great pleasure and honor that we
        welcome you to the 18th Asian Pacific Society of
        Atherosclerosis and Vascular Disease Congress (2025
        APSAVD Congress), taking place at the Taipei
        International Convention Center (TICC) in the
        vibrant city of Taipei, Taiwan, on September 20-21,
        2025. <br />
        This congress serves as a premier platform for
        experts, researchers, and healthcare professionals
        from across the globe to converge, share, and
        exchange the latest scientific advancements,
        clinical practices, and innovations in the field of
        atherosclerosis and vascular disease. We are
        privileged to host a distinguished lineup of
        speakers and presenters who will deliver insightful
        lectures, engage in thought-provoking discussions,
        and inspire groundbreaking ideas.
        <br /> Taipei, renowned for its rich cultural
        heritage, cutting-edge technology, and world-class
        hospitality, provides an exceptional backdrop for
        this significant event. We hope you will take the
        opportunity to explore the city&apos;s unique blend
        of tradition and modernity, savor its exquisite
        cuisine, and experience the warmth of its people.
        <br /> We extend our deepest gratitude to the
        organizing committee, sponsors, and all participants
        for their unwavering support and dedication. Your
        contributions are invaluable in making this congress
        a resounding success.
        <br /> We cordially welcome your presence, and wish
        you an enjoyable experience at 2025 APSAVD Congress.
        <br />
        Yours sincerely,
        <img
          src={`${process.env.PUBLIC_URL}/images/home-img-2.png`}
          alt="home-img-2"
        />
        <p className="fw-500">Ping-Yen Liu, M.D., Ph.D.</p>
        <p>Congress President, 2025 APSAVD</p>
      </div>
    </section>
  );
};

export default WelcomeMessage;
