import { useState } from 'react';
import './quickLink.scss';
import { useNavigate } from 'react-router-dom';

const QuickLinks = ({ quickLinks }) => {
  const [hoveredImg, setHoveredImg] = useState(null);
  const nav = useNavigate();

  const handleMouseEnter = imgName => {
    setHoveredImg(imgName);
  };

  const handleMouseLeave = () => {
    setHoveredImg(null);
  };

  const renderLink = (
    item,
    imgName,
    defaultImg,
    hoverImg
  ) =>
    item && (
      <a href={item.link} rel="noopener noreferrer">
        <img
          src={
            hoveredImg === imgName
              ? `${process.env.PUBLIC_URL}/images/${hoverImg}`
              : `${process.env.PUBLIC_URL}/images/${defaultImg}`
          }
          alt={imgName}
          onMouseEnter={() => handleMouseEnter(imgName)}
          onMouseLeave={handleMouseLeave}
        />
      </a>
    );

  return (
    <div className="quick-link-container">
      <div className="fz-30 fw-800 mx-10">QUICK LINKS</div>
      <div className="quick-link-item">
        <div className="item-left">
          {renderLink(
            quickLinks?.[2],
            'abstract-submission',
            'home-link-btn-abstract-submission.png',
            'home-link-btn-abstract-submission-hover.png'
          )}
        </div>
        <div className="item-right">
          {renderLink(
            quickLinks?.[0],
            'program',
            'home-link-btn-program.png',
            'home-link-btn-program-hover.png'
          )}
          {renderLink(
            quickLinks?.[1],
            'registration',
            'home-link-btn-registration.png',
            'home-link-btn-registration-hover.png'
          )}
        </div>
      </div>
    </div>
  );
};

export default QuickLinks;
