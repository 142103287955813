import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRequest } from '../context/RequestContext';

const VerifyEmailToken = () => {
  const nav = useNavigate();
  const { token } = useParams();
  const { request, loading } = useRequest();

  const handleValidate = async () => {
    const result = await request(
      '/custom/v1/verify-email',
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          token,
        }),
      }
    );

    if (!result.success) {
      alert(result.message);
      nav('/');
    } else {
      nav('/login');
    }
  };

  useEffect(() => {
    handleValidate();
  }, []);
};

export default VerifyEmailToken;
