import Select from 'react-select';
import './auth.scss';
import { COUNTRY_OPTIONS } from '../../store';
import Input from '../Input';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../../context/UserContext';
import { useEffect } from 'react';

const RegisterForm = ({ onSuccess }) => {
  const { handleRegister, resetStatus, loading, success } =
    useAuth();
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleSubmit,
    handleReset,
  } = useFormik({
    initialValues: {
      country: { label: '', value: '' },
      city: '',
      title: { label: '', value: '' },
      gender: '',
      firstName: '',
      middleName: '',
      lastName: '',
      chineseName: '',
      institution: '',
      chineseInstitution: '',
      contactTel: '',
      email: '',
      password: '',
      retypePassword: '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      country: Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      }),
      city: Yup.string().required('required'),
      title: Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      }),
      gender: Yup.string().required(),
      firstName: Yup.string().required('required'),
      lastName: Yup.string().required('required'),
      institution: Yup.string().required('required'),
      contactTel: Yup.string().required('required'),
      email: Yup.string()
        .email('Invalid email')
        .required('required'),
      password: Yup.string().required('required'),
      retypePassword: Yup.string()
        .oneOf(
          [Yup.ref('password'), null],
          'Passwords do not match. Please try again.',
          'required'
        )
        .required('required'),
    }),
    onSubmit: rest => {
      const {
        country,
        title,
        firstName,
        lastName,
        middleName,
        chineseName,
        chineseInstitution,
        contactTel,
        retypePassword,
        ...data
      } = rest;

      const body = {
        ...data,
        first_name: firstName,
        last_name: lastName,
        middle_name: middleName,
        contact_tel: contactTel,
        country: country.value,
        title: title.value,
      };

      switch (body.country) {
        case 'Taiwan':
        case 'Hong Kong':
        case 'Macao':
        case 'China':
          body['chinese_name'] = chineseName;
          body['chinese_institution'] = chineseInstitution;
          break;
      }

      handleRegister(body);
    },
  });

  useEffect(() => {
    if (!loading && success) {
      handleReset();
      onSuccess(true);
      resetStatus();
    }
  }, [loading, success]);

  return (
    <form
      className="auth-form"
      style={{ marginTop: '40px', width: '600px' }}
      onSubmit={handleSubmit}
    >
      <div className="form-group">
        <label className="require">Country</label>
        <Select
          styles={{
            control: provided => ({
              ...provided,
              minWidth: '400px',
              borderColor:
                errors.country?.value && touched.country
                  ? 'red'
                  : provided.borderColor,
            }),
          }}
          onChange={option =>
            setFieldValue('country', option)
          }
          options={COUNTRY_OPTIONS.map(option => ({
            label: option,
            value: option,
          }))}
          placeholder="Select a country"
          value={values.country}
        />
      </div>
      <div className="form-group">
        <label className="require">City</label>
        <Input
          type="text"
          id="city"
          onChange={handleChange}
          value={values.city}
          invalidate={errors.city && touched.city}
          errorMessage={errors.city}
        />
      </div>
      <div className="form-group">
        <label className="require">Title</label>
        <Select
          styles={{
            control: provided => ({
              ...provided,
              minWidth: '400px',
              borderColor:
                errors.title?.value && touched.title
                  ? 'red'
                  : provided.borderColor,
            }),
          }}
          onChange={option =>
            setFieldValue('title', option)
          }
          options={[
            {
              label: 'Prof.',
              value: 'Prof.',
            },
            {
              label: 'Dr.',
              value: 'Dr.',
            },
            {
              label: 'Mr.',
              value: 'Mr.',
            },
            {
              label: 'Mrs.',
              value: 'Mrs.',
            },
            {
              label: 'Ms.',
              value: 'Ms.',
            },
          ]}
          placeholder="Select ..."
          value={values.title}
        />
      </div>
      <div className="form-group">
        <label className="require">Gender</label>
        <div className="auth-operation-group">
          <input
            type="radio"
            id="male"
            className="gender"
            onChange={e => {
              e.target.checked &&
                setFieldValue('gender', e.target.value);
            }}
            value={'Male'}
            checked={values.gender === 'Male'}
          />
          <label htmlFor="male">Male</label>
        </div>
        <div>
          <input
            type="radio"
            id="female"
            className="gender"
            onChange={e => {
              e.target.checked &&
                setFieldValue('gender', e.target.value);
            }}
            value={'Female'}
            checked={values.gender === 'Female'}
          />
          <label htmlFor="female">Female</label>
        </div>
        {errors.gender && touched.gender && (
          <div
            style={{ marginLeft: 'auto' }}
            className="error-msg"
          >
            required
          </div>
        )}
      </div>
      <div className="form-group">
        <label className="require">First Name</label>
        <Input
          type="text"
          id="firstName"
          onChange={handleChange}
          value={values.firstName}
          invalidate={errors.firstName && touched.firstName}
          errorMessage={errors.firstName}
        />
      </div>
      <div className="form-group">
        <label>Middle Name</label>
        <Input
          type="text"
          id="middleName"
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label className="require">Last Name</label>
        <Input
          type="text"
          id="lastName"
          onChange={handleChange}
          value={values.lastName}
          invalidate={errors.lastName && touched.lastName}
          errorMessage={errors.lastName}
        />
      </div>
      {(values.country.value === 'Taiwan' ||
        values.country.value === 'Hong Kong' ||
        values.country.value === 'Macao' ||
        values.country.value === 'China') && (
        <div className="form-group">
          <label>中文姓名</label>
          <Input
            type="text"
            id="chineseName"
            onChange={handleChange}
            value={values.chineseName}
          />
        </div>
      )}
      <div className="form-group">
        <label className="require">
          Institution/Organization
        </label>
        <Input
          type="text"
          id="institution"
          onChange={handleChange}
          value={values.institution}
          invalidate={
            errors.institution && touched.institution
          }
          errorMessage={errors.institution}
        />
      </div>
      {(values.country.value === 'Taiwan' ||
        values.country.value === 'Hong Kong' ||
        values.country.value === 'Macao' ||
        values.country.value === 'China') && (
        <div className="form-group">
          <label>單位中文名稱</label>
          <Input
            type="text"
            id="chineseInstitution"
            onChange={handleChange}
            value={values.chineseInstitution}
          />
        </div>
      )}
      <div className="form-group">
        <label className="require">Contact Tel</label>
        <Input
          type="text"
          id="contactTel"
          onChange={handleChange}
          value={values.contactTel}
          invalidate={
            errors.contactTel && touched.contactTel
          }
          errorMessage={errors.contactTel}
        />
      </div>
      <div className="form-group">
        <label className="require">Email</label>
        <Input
          type="text"
          id="email"
          onChange={handleChange}
          value={values.email}
          invalidate={errors.email && touched.email}
          errorMessage={errors.email}
        />
      </div>
      <div className="form-group">
        <label className="require">Password</label>
        <Input
          type="password"
          id="password"
          onChange={handleChange}
          value={values.password}
          invalidate={errors.password && touched.password}
          errorMessage={errors.password}
        />
      </div>
      <div className="form-group">
        <label className="require">Retype Password</label>
        <Input
          type="password"
          id="retypePassword"
          onChange={handleChange}
          value={values.retypePassword}
          invalidate={
            errors.retypePassword && touched.retypePassword
          }
          errorMessage={errors.retypePassword}
        />
      </div>
      {/* <div style={{ width: '100%' }}> */}
      <button
        className="auth-button"
        type="submit"
        // disabled={loading}
      >
        Register
      </button>
      {/* <button>Cancel</button> */}
      {/* </div> */}
    </form>
  );
};

export default RegisterForm;
