import { useEffect, useState } from 'react';
import SubmissionForm from '../../components/submission/SubmissionForm';

const SubmissionCreate = () => {
  return (
    <>
      <div className="container">
        <div className="page-title fz-28">
          Abstract Submission
        </div>
        <SubmissionForm />
      </div>
    </>
  );
};

export default SubmissionCreate;
