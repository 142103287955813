import jsPDF from 'jspdf';
import { useEffect, useRef } from 'react';

const Preview = () => {
  const pdfRef = useRef(new jsPDF());
  const previewRef = useRef(null);

  useEffect(() => {
    if (pdfRef.current && previewRef.current) {
      pdfRef.current.setFont('normal');
      // 標題
      const text =
        'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...';
      const margin = 20;
      pdfRef.current.setFontSize(14);
      const pageWidth =
        pdfRef.current.internal.pageSize.width;
      let textLines = pdfRef.current.splitTextToSize(
        text,
        pageWidth - margin * 2
      );

      pdfRef.current.text(textLines, margin, 20);

      // 作者
      const contributors = [
        {
          name: 'Kiwi Yang',
          organizations: [
            {
              label: 'Pharmedia',
              value: 1,
            },
            {
              label: 'Hoyoung',
              value: 2,
            },
          ],
        },
        {
          name: 'Lavan Li',
          organizations: [
            {
              label: 'Hoyoung',
              value: 1,
            },
          ],
        },
        {
          name: 'Henza Keri Mizort',
          organizations: [
            {
              label: 'HoFa',
              value: 3,
            },
          ],
        },
      ];

      // 服務單位
      const organizations = [
        {
          label: 'Pharmedia',
          value: 1,
        },
        {
          label: 'Hoyoung',
          value: 2,
        },
        {
          label: 'HoFa',
          value: 3,
        },
      ];

      let contributorString = '';
      contributors.forEach((contributor, idx) => {
        const str = `<span style="font-size: 4px; margin-right: 2px; font-family: Times New Roman"><sup style="margin-left: 0px;">${contributor.organizations.map(o => organizations.findIndex(val => val.label === o.label) + 1).join(',')}</sup>${contributor.name.replace(/ /g, '&nbsp;')}${idx < contributors.length - 1 ? ',' : ''}</span>`;
        contributorString += str;
      });

      const div = document.createElement('div');
      // div.style.marginTop = '5px';
      div.innerHTML = contributorString;

      // 建立服務單位列表
      let organizationList = organizations
        .map(
          (organization, idx) =>
            `<div style="font-size: 3.5px; line-height: 2px; font-family: Times New Roman">${idx + 1}&nbsp;${organization.label}<div>`
        )
        .join('&nbsp;');

      const organizationDiv = document.createElement('div');
      organizationDiv.innerHTML = organizationList;

      const combine = document.createElement('div');
      // combine.style.lineHeight = 1;
      // combine.style.marginTop = '10px';
      combine.appendChild(div);
      combine.appendChild(organizationDiv);
      pdfRef.current.html(combine, {
        margin: [20, 0, 0, margin],
        callback: () => {
          // 輸出成 data string
          const content =
            pdfRef.current.output('datauristring');
          previewRef.current.src = content;
        },
      });
      // let contributorString = '';
      // contributorList.for
    }
  }, [pdfRef, previewRef]);

  return (
    <iframe
      ref={previewRef}
      style={{
        width: '100%',
        height: '99dvh',
        boxSizing: 'border-box',
      }}
    ></iframe>
  );
};

export default Preview;
