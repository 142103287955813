import '../../assets/scss/form.scss';
import Input from '../Input';
import useFetch from '../../hooks/useFetch';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
// import usePost from '../../hooks/usePost';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FaCheck, FaPlus } from 'react-icons/fa';
import Collapse from '../../components/Collapse';
import FileDropzone from '../../components/FileDropzone';
import { Editor } from '@tinymce/tinymce-react';
import Select from 'react-select';
import jsPDF from 'jspdf';
// import FileThumbnail from '../FileThumbnail';

const titleOptions = [
  {
    label: 'Prof.',
    value: 'Prof.',
  },
  {
    label: 'Dr.',
    value: 'Dr.',
  },
  {
    label: 'Mr.',
    value: 'Mr.',
  },
  {
    label: 'Mrs.',
    value: 'Mrs.',
  },
  {
    label: 'Ms.',
    value: 'Ms.',
  },
];

const SubmissionForm = () => {
  // const pdfRef = useRef(new jsPDF());
  const { id } = useParams();
  const nav = useNavigate();
  const { token } = useContext(AuthContext);

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // const { data: template, fetchData } = useFetch(
  //   '/abstract-template'
  // );

  useEffect(() => {
    const checkScreenSize = () => {
      if (window.innerWidth < 1024 && !isSmallScreen) {
        setIsSmallScreen(true);
        Swal.fire({
          text: 'For the best experience, please access this site on a desktop device.',
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          showClass: { popup: '' },
          hideClass: { popup: '' },
        });
      } else if (
        window.innerWidth >= 1024 &&
        isSmallScreen
      ) {
        setIsSmallScreen(false);
        Swal.close();
      }
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, [isSmallScreen]);

  const handleCancel = () => {
    Swal.fire({
      text: 'Would you kindly confirm if you would like to save the changes?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
      customClass: {
        confirmButton: 'swal-btn swal-confirm',
        cancelButton: 'swal-btn swal-cancel',
      },
      showClass: { popup: '' },
      hideClass: { popup: '' },
    }).then(result => {
      if (result.isConfirmed) {
        nav(-1);
      }
    });
  };

  const ErrorMessage = ({ error, touched, id }) => {
    if (!error || !touched) return null;
    if (
      id === 'retypePassword' &&
      error === 'Passwords must match'
    ) {
      return null;
    }

    return (
      <span className="error-msg fz-12">
        *required field
      </span>
    );
  };
  // const { postData, success, error, data } =
  //   usePost('/submission');
  const { data: options } = useFetch(
    '/submission-category'
  );

  const { data: submission } = useFetch(
    `/submission/${id}`
  );

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    isValid,
    isSubmitting,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      id,
      abstractTitle: submission?.success
        ? submission.data.abstractTitle
        : '',
      category: submission?.success
        ? submission.data.category
        : '',
      organizations: [],
      contributors: [],
      presentationType: submission?.success
        ? submission?.data?.presentationType?.value
        : '',
      yia: submission?.success ? submission.data.yia : '',
      cvFile: submission?.success
        ? submission.data.cvFile
        : null,
    },
    validationSchema: yup.object().shape({
      abstractTitle: yup.string().required(),
      category: yup.object().shape({
        value: yup.string().required(),
      }),
      presentationType: yup.string().required(),

      // yia: yup.string(),
      cvFile: yup
        .mixed()
        .nullable()
        .when('yia', {
          is: val => val === 'Attend',
          then: () => yup.mixed().required(),
          otherwise: () => yup.mixed().nullable(),
        }),
      // tg: yup.string(),
    }),

    onSubmit: () => {
      // // todo 先預覽 pdf 效果
      // pdfRef.current.text(values.abstractTitle, 10, 10);
    },
  });

  const handleUpdateSubmission = body => {
    return fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_API_URI}${process.env.REACT_APP_REST_API_URI}/submission/${id}`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }
    );
  };

  const handleUploadFile = ({ file, type }) => {
    // const { id: dataId } = data;
    const formData = new FormData();
    // formData.append('postId', data?.id || id);
    formData.append('type', type);
    formData.append('file', file);
    return fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_API_URI}${process.env.REACT_APP_REST_API_URI}/upload`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
  };

  const handleDeleteFile = url => {
    return fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_API_URI}${process.env.REACT_APP_REST_API_URI}/delete-file`,
      {
        method: 'POST',
        body: JSON.stringify({
          url,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  // useEffect(() => {
  //   if (success && data) {
  //     const { id } = data;
  //     const files = [];
  //     if (values.abstractFile) {
  //       const fileName = `${id}-${values.category.value}-${values.presentingAuthor.firstName}-${values.presentingAuthor.lastName}.${values.abstractFile.name.split('.').pop().toLowerCase()}`;
  //       const newName = fileName.replace(/\s+/g, '_');

  //       const abstractFile = new File(
  //         [values.abstractFile],
  //         newName,
  //         { type: values.abstractFile.type }
  //       );

  //       files.push({
  //         file: abstractFile,
  //         type: 'Abstract',
  //       });
  //     }

  //     if (values.yia === 'Attend' && values.cvFile) {
  //       const fileName = `CV_${values.presentingAuthor.firstName}-${values.presentingAuthor.lastName}.${values.cvFile.name.split('.').pop().toLowerCase()}`;
  //       const newName = fileName.replace(/\s+/g, '_');
  //       const file = new File([values.cvFile], newName, {
  //         type: values.cvFile.type,
  //       });
  //       files.push({ type: 'CV', file });
  //     }

  //     if (values.tg === 'Attend' && values.cvFileForTg) {
  //       const fileName = `CV_TG_${values.presentingAuthor.firstName}-${values.presentingAuthor.lastName}.${values.cvFileForTg.name.split('.').pop().toLowerCase()}`;
  //       const file = new File(
  //         [values.cvFileForTg],
  //         fileName,
  //         { type: values.cvFileForTg.type }
  //       );
  //       files.push({ type: 'CV for Travel Grant', file });
  //     }

  //     if (files.length > 0) {
  //       Promise.all(files.map(handleUploadFile))
  //         .then(results => {
  //           console.log(
  //             'All files uploaded successfully:',
  //             results
  //           );
  //           nav('/submission');
  //         })
  //         .catch(err => {
  //           console.error(
  //             'Some files failed to upload:',
  //             err
  //           );
  //         });
  //     } else {
  //       nav('/submission');
  //     }
  //   }
  // }, [success, data]);

  // useEffect(() => {
  //   if (error) {
  //     //
  //   }
  // }, [error]);

  useEffect(() => {
    // 表單驗證失敗
    if (!isValid && isSubmitting) {
      window.scrollTo(0, 0);
    }
  }, [isValid, isSubmitting]);

  // useEffect(() => {
  //   if (!id) {
  //     fetchData();
  //   }
  // }, []);

  const getFileName = url =>
    new URL(url).pathname.split('/').pop();

  return (
    <>
      <div className="form-container">
        <Collapse
          title="Abstract Submission System"
          requiredText="* = required field"
          isDefaultOpen={true}
        >
          <div className="form-group">
            <div className="form-item w-100">
              <label>
                Abstract Title
                <span className="require">*</span>
              </label>
              <Input
                id="abstractTitle"
                onChange={handleChange}
                value={values.abstractTitle}
                invalid={
                  errors.abstractTitle &&
                  touched.abstractTitle
                }
              />
              <ErrorMessage
                error={errors.abstractTitle}
                touched={touched.abstractTitle}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="form-item w-100">
              <label>
                Category Selection
                <span className="require">*</span>
              </label>
              <Input
                type="select"
                options={options}
                onChange={option =>
                  setFieldValue('category', option)
                }
                value={values.category}
                invalid={
                  errors.category && touched.category
                }
              />
              <ErrorMessage
                error={errors.category}
                touched={touched.category}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="form-item w-100">
              <label>
                Presentation Type
                <span className="require">*</span>
              </label>
              <div
                className="radio-group"
                data-presentationType-error={
                  errors.presentationType &&
                  touched.presentationType
                    ? 'true'
                    : 'false'
                }
              >
                {[
                  { label: 'Oral', value: 'Oral' },
                  { label: 'Poster', value: 'Poster' },
                ].map(option => (
                  <label
                    key={option.value}
                    className="radio-label"
                  >
                    <input
                      type="radio"
                      name="presentationType"
                      value={option.value}
                      checked={
                        values.presentationType ===
                        option.value
                      }
                      onChange={handleChange}
                    />
                    {option.label}
                  </label>
                ))}
                <ErrorMessage
                  error={errors.presentationType}
                  touched={touched.presentationType}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-item">
              <label>Institute / Organization List</label>
              <FaPlus
                onClick={() => {
                  setFieldValue('organizations', [
                    ...values.organizations,
                    { label: '', value: null },
                  ]);
                }}
              />
            </div>
            {values.organizations.map(
              (organization, idx) => (
                <div key={idx} className="w-100">
                  {idx + 1}
                  <Input
                    value={organization.label}
                    onChange={e => {
                      setFieldValue(
                        `organizations[${idx}]`,
                        {
                          value: e.target.value,
                          label: e.target.value,
                        }
                      );
                    }}
                  />
                </div>
              )
            )}
          </div>
          <div className="form-group">
            <div className="form-item">
              <label>Contributors</label>
              <FaPlus
                onClick={() => {
                  setFieldValue('contributors', [
                    ...values.contributors,
                    { name: '', organizations: [] },
                  ]);
                }}
              />
            </div>
            {values.contributors.map((contributor, idx) => (
              <div key={idx} className="w-100">
                <Input
                  value={contributor.name}
                  onChange={e => {
                    setFieldValue(`contributors[${idx}]`, {
                      ...contributor,
                      name: e.target.value,
                    });
                  }}
                />
                <Input
                  type="select"
                  isMulti
                  options={values.organizations.map(o => o)}
                  onChange={options => {
                    setFieldValue(`contributors[${idx}]`, {
                      ...contributor,
                      organizations: options,
                    });
                  }}
                  value={contributor.organizations}
                />
              </div>
            ))}
          </div>
          <div className="form-group">
            <div className="form-item">
              <label>
                Abstract Content
                <span className="require">*</span>
              </label>
              <Editor
                apiKey={`${process.env.REACT_APP_EDITOR_API_KEY}`}
                init={{
                  plugins: [
                    // Core editing features
                    'anchor',
                    'autolink',
                    'charmap',
                    'link',
                    'lists',
                    'media',
                    'searchreplace',
                    // 'table',
                    'visualblocks',
                    'wordcount',
                    // Your account includes a free trial of TinyMCE premium features
                    // Try the most popular premium features until Feb 4, 2025:
                    // 'checklist',
                    // 'mediaembed',
                    // 'casechange',
                    // 'export',
                    // 'formatpainter',
                    // 'pageembed',
                    // 'a11ychecker',
                    // 'tinymcespellchecker',
                    // 'permanentpen',
                    // 'powerpaste',
                    // 'advtable',
                    // 'advcode',
                    // 'editimage',
                    // 'advtemplate',

                    // 'mentions',
                    // 'tinycomments',
                    // 'tableofcontents',
                    // 'footnotes',
                    // // 'mergetags',
                    // 'autocorrect',
                    // 'typography',
                    // 'inlinecss',
                    // 'markdown',
                    // 'importword',
                    // 'exportword',
                    // 'exportpdf',
                  ],
                  toolbar:
                    'undo redo | blocks | bold italic underline strikethrough link | align lineheight checklist numlist bullist indent outdent',
                  tinycomments_mode: 'embedded',
                  tinycomments_author: 'Author name',
                }}
                initialValue="Welcome to TinyMCE!"
                onEditorChange={content => {
                  // console.log(content);
                }}
              />
            </div>
          </div>
          {/* <div className="form-group">
            <div className="form-item w-100">
              <label>
                Presenting Author/Corresponding Author
              </label>
            </div>
          </div> */}
          {/* <div className="submission-form-style">
            <div className="w-20">
              <label>Author Role</label>
              <p>Presenting Author</p>
            </div>
            <div className="w-10">
              <label>
                Title<span className="require">*</span>
              </label>
              <Input
                type="select"
                classNamePrefix="form-group"
                options={titleOptions}
                onChange={option =>
                  setFieldValue(
                    'presentingAuthor["title"]',
                    option
                  )
                }
                value={values.presentingAuthor.title}
                invalid={
                  errors?.presentingAuthor?.title &&
                  touched?.presentingAuthor?.title
                }
              />
            </div>
            <div className="w-10">
              <label>
                First Name
                <span className="require">*</span>
              </label>
              <Input
                id='presentingAuthor["firstName"]'
                onChange={handleChange}
                value={values.presentingAuthor.firstName}
                invalid={
                  errors.presentingAuthor?.firstName &&
                  touched.presentingAuthor?.firstName
                }
              />
            </div>
            <div className="w-10">
              <label>
                Last Name<span className="require">*</span>
              </label>
              <Input
                id='presentingAuthor["lastName"]'
                onChange={handleChange}
                value={values.presentingAuthor.lastName}
                invalid={
                  errors.presentingAuthor?.lastName &&
                  touched.presentingAuthor?.lastName
                }
              />
            </div>
            <div className="w-20">
              <label>
                Email<span className="require">*</span>
              </label>
              <Input
                id='presentingAuthor["email"]'
                onChange={handleChange}
                value={values.presentingAuthor.email}
                invalid={
                  errors.presentingAuthor?.email &&
                  touched.presentingAuthor?.email
                }
              />
            </div>
            <div className="w-20">
              <label>Institution/Organization</label>
              <Input
                id='presentingAuthor["institution"]'
                onChange={handleChange}
                value={values.presentingAuthor.institution}
              />
            </div>
          </div>
          <div className="submission-form-style pb-20">
            <div className="w-20">
              <p>Corresponding Author</p>
            </div>
            <div className="w-10">
              <Input
                type="select"
                classNamePrefix="form-group"
                options={titleOptions}
                onChange={option =>
                  setFieldValue(
                    'correspondingAuthor["title"]',
                    option
                  )
                }
                value={values.correspondingAuthor.title}
                invalid={
                  errors?.correspondingAuthor?.title &&
                  touched?.correspondingAuthor?.title
                }
              />
            </div>
            <div className="w-10">
              <Input
                id='correspondingAuthor["firstName"]'
                onChange={handleChange}
                value={values.correspondingAuthor.firstName}
                invalid={
                  errors.correspondingAuthor?.firstName &&
                  touched.correspondingAuthor?.firstName
                }
              />
            </div>
            <div className="w-10">
              <Input
                id='correspondingAuthor["lastName"]'
                onChange={handleChange}
                value={values.correspondingAuthor.lastName}
                invalid={
                  errors.correspondingAuthor?.lastName &&
                  touched.correspondingAuthor?.lastName
                }
              />
            </div>
            <div className="w-20">
              <Input
                id='correspondingAuthor["email"]'
                onChange={handleChange}
                value={values.correspondingAuthor.email}
                invalid={
                  errors.correspondingAuthor?.email &&
                  touched.correspondingAuthor?.email
                }
              />
            </div>
            <div className="w-20">
              <Input
                id='correspondingAuthor["institution"]'
                onChange={handleChange}
                value={
                  values.correspondingAuthor.institution
                }
              />
            </div>
          </div> */}
        </Collapse>
        <div className="form-item mt-40">
          <Collapse
            title="Young Investigator Award Eligibility"
            requiredText="* = required field"
            isDefaultOpen={true}
            className={
              errors.cvFile && touched.cvFile
                ? 'error-border'
                : ''
            }
          >
            <div className="collapse-content">
              <p className="mx-10">
                • Applicant must submit the training
                certification and curriculum vitae.
              </p>
              <p className="mx-10">
                • Applicant must be aged 45 years or younger
                at the time of application (birth year after
                1980).
              </p>
              <p className="mx-10">
                • Applicant must be the first author of
                his/her abstract and a registered
                participant of the conference.
              </p>
              <p className="mx-10">
                • The presenter must join 2025 APASL STC
                Oncology as an in-person participant to
                compete for the award.
              </p>
              <p className="mx-10">
                • Abstracts will be shortlisted based on
                scores assigned by the Abstract Selection
                Committee.
              </p>
              <div
                className="checkbox-group fw-500 my-20"
                onClick={() =>
                  setFieldValue(
                    'yia',
                    values.yia === 'Attend'
                      ? 'Not Attend'
                      : 'Attend'
                  )
                }
              >
                <div
                  className={`checkbox-style ${
                    values.yia === 'Attend' ? 'checked' : ''
                  }`}
                >
                  {values.yia === 'Attend' && <FaCheck />}
                </div>
                <span>Attend</span>
              </div>

              {values.yia === 'Attend' && (
                <>
                  <label>Curriculum Vitae File</label>
                  <FileDropzone
                    maxSize={10}
                    acceptType={{
                      '.pdf': [],
                      '.doc': [],
                      '.docx': [],
                    }}
                    setFieldValue={setFieldValue}
                    file={values.cvFile}
                    field={'cvFile'}
                  />

                  {typeof values.cvFile === 'string' && (
                    <a
                      href={values.cvFile}
                      download={getFileName(values.cvFile)}
                    >
                      Curriculum Vitae File
                    </a>
                  )}
                </>
              )}
            </div>
          </Collapse>
        </div>
        {/* <div className="form-item mt-40">
          <Collapse
            title="Travel Grant Eligibility"
            requiredText="* = required field"
            isDefaultOpen={true}
            className={
              errors.cvFileForTg && touched.cvFileForTg
                ? 'error-border'
                : ''
            }
          >
            <div className="collapse-content">
              <p className="mx-10">
                • Applicant must submit the curriculum vitae
                via submission system.
              </p>
              <p className="mx-10">
                • The author must be of a nationality from
                the Asia Pacific region (Excluding Taiwan as
                it is the conference location).
              </p>
              <p className="mx-10">
                • The presenter must join 2025 APASL STC
                oncology as in-person participant to compete
                the award.
              </p>
              <p className="mx-10">
                • The author must be the presenting author
                of his/her abstract and a registered
                participant of the conference.
              </p>
              <p className="mx-10">
                • The shortlisted abstract must be presented
                by the applicant during the meeting.
              </p>
              <p className="mx-10">
                • Priority will be given to the countries
                with limited opportunities to attend
                education and training activities.
              </p>
              <div
                className="checkbox-group fw-500 my-20"
                onClick={() =>
                  setFieldValue(
                    'tg',
                    values.tg === 'Attend'
                      ? 'Not Attend'
                      : 'Attend'
                  )
                }
              >
                <div
                  className={`checkbox-style ${
                    values.tg === 'Attend' ? 'checked' : ''
                  }`}
                >
                  {values.tg === 'Attend' && <FaCheck />}
                </div>
                <span>Attend</span>
              </div>
              {values.tg === 'Attend' && (
                <>
                  <label>Curriculum Vitae File</label>
                  <FileDropzone
                    maxSize={10}
                    acceptType={{
                      '.doc': [],
                      '.docx': [],
                      '.pdf': [],
                    }}
                    file={values.cvFileForTg}
                    setFieldValue={setFieldValue}
                    field={'cvFileForTg'}
                  />

                  {typeof values.cvFileForTg ===
                    'string' && (
                    <a
                      href={values.cvFileForTg}
                      download={getFileName(
                        values.cvFileForTg
                      )}
                    >
                      Curriculum Vitae File for Travel Grant
                    </a>
                  )}
                </>
              )}
            </div>
          </Collapse>
        </div> */}
        <div className="button-group">
          <button
            className="secondary-btn fw-800 mt-40"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="primary-btn fw-800 mt-40"
            onClick={e => {
              //       e.preventDefault();
              //       if (!id) {
              //         Swal.fire({
              //           showCancelButton: true,
              //           confirmButtonText: 'Create',
              //           cancelButtonText: 'Cancel',
              //           reverseButtons: true,
              //           customClass: {
              //             confirmButton: 'swal-btn swal-confirm',
              //             cancelButton: 'swal-btn swal-cancel',
              //           },
              //           showClass: { popup: '' },
              //           hideClass: { popup: '' },
              //           html: `
              // 					<div class="fz-20 fw-800 form-title">
              //          <b> Review & Submit </b>
              //        </div>
              // 	<div class="text-left mt-20 fw-500">
              //  Please review the abstract and authors’ information” to revise the submission or “Submit the Abstract” to complete the submission process. You may edit your submitted abstract before the deadline <span class='color-red fw-800'>23:59, April 30, 2025 (GMT+8)</span>.
              // </div>`,
              //         }).then(result => {
              //           if (result.isConfirmed) {
              //             handleSubmit();
              //           }
              //         });
              //       } else {
              //         handleSubmit();
              //       }
              handleSubmit();
            }}
          >
            {id ? 'Save' : 'Create'}
          </button>
        </div>
      </div>
    </>
  );
};

export default SubmissionForm;
