import Select, { components } from 'react-select';
import './input.scss';

import PropTypes from 'prop-types';
import React from 'react';

const CustomDropdownIndicator = props => {
  const { selectProps } = props;
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          transform: selectProps.menuIsOpen
            ? 'rotate(180deg)'
            : 'rotate(0deg)',
          transition: 'transform 0.3s ease',
        }}
      >
        <path
          d="M7 10L12 15L17 10"
          stroke="#A2A2A2"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </components.DropdownIndicator>
  );
};

const Input = ({ invalid, options = [], ...props }) => {
  if (props.type === 'select') {
    const classNamePrefix =
      props.classNamePrefix || 'default';

    return (
      <Select
        {...props}
        isDisabled={props.disabled}
        options={options}
        classNamePrefix={classNamePrefix}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            marginTop: '5px',
            borderColor: state.isDisabled
              ? 'transparent'
              : invalid
                ? '#ff4a4a'
                : state.isFocused
                  ? 'rgba(0, 0, 0, 0.15)'
                  : 'rgba(0, 0, 0, 0.1)',
            borderWidth: '1px',
            '&:hover': {
              borderColor: 'rgba(0, 0, 0, 0.15)',
            },
          }),
          menu: baseStyles => ({
            ...baseStyles,
            marginTop: '5px',
            zIndex: 99,
          }),
          menuList: baseStyles => ({
            ...baseStyles,
            paddingTop: '0px',
            paddingBottom: '0px',
          }),
          option: (
            baseStyles,
            { isSelected, isFocused, data, options }
          ) => {
            const index = options.indexOf(data);
            const firstItemStyle =
              index === 0
                ? {
                    borderTopLeftRadius: '4px',
                    borderTopRightRadius: '4px',
                  }
                : {};
            const lastItemStyle =
              index === options.length - 1
                ? {
                    borderBottomLeftRadius: '4px',
                    borderBottomRightRadius: '4px',
                  }
                : {};
            return {
              ...baseStyles,

              ...firstItemStyle,
              ...lastItemStyle,
              '&:active': {
                backgroundColor: '#F6F7F9',
              },
            };
          },
          indicatorSeparator: baseStyles => ({
            ...baseStyles,
            display: 'none',
          }),
        }}
        components={{
          DropdownIndicator: CustomDropdownIndicator,
        }}
        value={props.value}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#F6F7F9',
            primary: 'var(--primary-blue)',
          },
        })}
        menuIsOpen={props.menuIsOpen}
      />
    );
  }

  return (
    <input
      {...props}
      className={`common-input-style ${invalid ? 'invalid' : ''}`}
    />
  );
};

Input.propTypes = {
  invalid: PropTypes.bool,
  options: PropTypes.array,
  menuIsOpen: PropTypes.bool,
};

export default Input;
