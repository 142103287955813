import { Fragment } from 'react';
import Header from './header/Header';
import { Outlet } from 'react-router-dom';
import Banner from '../components/banner/Banner';

const Layout = () => {
  return (
    <Fragment>
      <Header />
      <Banner />
      <Outlet />
    </Fragment>
  );
};
export default Layout;
