import moment from 'moment';
import './countdown.scss';
import { useEffect, useRef, useState } from 'react';
const Countdown = () => {
  const [styles, setStyles] = useState({});
  const updateStyles = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth > 1480 && screenWidth <= 1920) {
      setStyles({
        left: `${700 - (1920 - screenWidth) * 0.5}px`,
      });
    } else if (screenWidth > 1920 && screenWidth <= 2559) {
      setStyles({
        left: `${750 - (1920 - screenWidth) * 0.2}px`,
        top: `${5 - (screenWidth - 1920) * 0.05}px`,
        marginLeft: `${5 - (1920 - screenWidth) * 0.05}px`,
        marginTop: `${5 - (screenWidth - 1920) * 0.04}px`,
      });
    } else if (screenWidth > 2559) {
      setStyles({
        left: `${800 - (1920 - screenWidth) * 0.2}px`,
        top: `${5 - (screenWidth - 1920) * 0.05}px`,
        marginLeft: `${5 - (1920 - screenWidth) * 0.05}px`,
        marginTop: `${5 - (screenWidth - 1920) * 0.04}px`,
      });
    }
  };

  useEffect(() => {
    updateStyles();
    window.addEventListener('resize', updateStyles);
    return () =>
      window.removeEventListener('resize', updateStyles);
  }, []);

  const deadline = useRef(moment('2025-09-20 00:00:00'));

  const duration = moment.duration(
    deadline.current.diff(moment())
  );
  const [date, setDate] = useState({
    days: Math.floor(duration.asDays()),
    hours: duration.hours(),
    minutes: duration.minutes(),
    seconds: duration.seconds(),
  });

  useEffect(() => {
    const timer = setInterval(() => {
      const duration = moment.duration(
        deadline.current.diff(moment())
      );

      // 避免出現負數
      if (duration.asSeconds() <= 0) {
        clearInterval(timer);
        setDate({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
      } else {
        setDate({
          days: Math.floor(duration.asDays()),
          hours: duration.hours(),
          minutes: duration.minutes(),
          seconds: duration.seconds(),
        });
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [deadline]);
  return (
    <div
      className="countdown-container"
      style={{
        left: styles.left,
      }}
    >
      <p
        style={{
          top: styles.top,
        }}
      >
        COUNTDOWN to
        <br /> APSAVD 2025
      </p>
      <div
        className="countdown-number"
        style={{
          marginLeft: styles.marginLeft,
        }}
      >
        <div
          className="number fw-800"
          style={{
            marginTop: styles.marginTop,
          }}
        >
          {date.days}
        </div>
        <div>DAYS</div>
      </div>
      <div
        className="countdown-number"
        style={{
          marginLeft: styles.marginLeft,
        }}
      >
        <div
          className="number fw-800"
          style={{
            marginTop: styles.marginTop,
          }}
        >
          {date.hours}
        </div>
        <div>HOURS</div>
      </div>
      <div
        className="countdown-number"
        style={{
          marginLeft: styles.marginLeft,
        }}
      >
        <div
          className="number fw-800"
          style={{
            marginTop: styles.marginTop,
          }}
        >
          {date.minutes}
        </div>
        <div>MINUTES</div>
      </div>
      <div
        className="countdown-number"
        style={{
          marginLeft: styles.marginLeft,
        }}
      >
        <div
          className="number fw-800"
          style={{
            marginTop: styles.marginTop,
          }}
        >
          {date.seconds}
        </div>
        <div>SECONDS</div>
      </div>
    </div>
  );
};

export default Countdown;
