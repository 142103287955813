import { FaMinus, FaPlus } from 'react-icons/fa';
import './collapse.scss';
import { useState } from 'react';

const Collapse = ({
  className,
  title,
  children,
  isRequired,
  isDefaultOpen,
  requiredText,
}) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen);
  return (
    <div className={`collapse ${className}`}>
      <div
        className="collapse-header fz-20 fw-900"
        onClick={() => setIsOpen(prev => !prev)}
      >
        <div>
          {title}

          {isRequired && (
            <span className="color-red fz-18">*</span>
          )}
          <span className="fz-14 text-center mx-10">
            {requiredText}
          </span>
        </div>
        <div className="collapse-header-button">
          {isOpen ? <FaMinus /> : <FaPlus />}
        </div>
      </div>
      <div
        className={`collapse-body ${isOpen ? 'collapsed' : ''}`}
      >
        {children}
      </div>
    </div>
  );
};

export default Collapse;
