import { useFormik } from 'formik';
import Input from '../Input';
import * as Yup from 'yup';
import { useAuth } from '../../context/UserContext';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
  const nav = useNavigate();
  const {
    handleLogin,
    token,
    handleValidate,
    loading,
    success,
    resetStatus,
  } = useAuth();
  const {
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
      isRemember: false,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Invalid Email')
        .required('Required'),
      password: Yup.string()
        .required('Invalid Password')
        .required('Required'),
    }),
    onSubmit: () => {
      handleLogin({
        email: values.email,
        password: values.password,
      });
    },
  });

  const handleRedirect = url => {
    nav(url);
  };

  useEffect(() => {
    handleValidate({
      onSuccess: () => {
        nav('/');
      },
      onError: () => {
        alert(
          'Your session has expired. Please log in again.'
        );
        nav('/login');
      },
    });
  }, [token]);

  return (
    <form className="auth-form" onSubmit={handleSubmit}>
      <h3>Log in</h3>
      <div>
        <span>Don&apos;t have an account?</span>
        <span
          className="login-highlight"
          onClick={() => handleRedirect('/register')}
        >
          Create one &gt;&gt;
        </span>
      </div>
      <div className="login-divider">OR</div>
      <Input
        placeholder="Email Address"
        type="text"
        id="email"
        name="email"
        onChange={handleChange}
        value={values.email}
        invalidate={errors.email && touched.email}
        errorMessage={errors.email}
        style={{ marginBottom: '20px' }}
      />
      <Input
        placeholder="Password"
        type="password"
        id="password"
        name="password"
        onChange={handleChange}
        value={values.password}
        invalidate={errors.password && touched.password}
        errorMessage={errors.password}
        style={{ marginBottom: '10px' }}
      />
      <div className="auth-operation-group">
        <div className="button-group">
          <input
            type="checkbox"
            name="remember-me"
            id="remember-me"
            checked={values.isRemember}
            onChange={e => {
              const checked = e.target.checked;
              setFieldValue('isRemember', checked);
            }}
          />
          <label htmlFor="remember-me">Remember me</label>
        </div>
        <div className="login-highlight">
          Forgot your password?
        </div>
      </div>
      <button
        className="auth-button"
        type="submit"
        disabled={loading}
      >
        Log in
      </button>
    </form>
  );
};

export default LoginForm;
