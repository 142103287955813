import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

import '../news/news.scss';
import useFetch from '../../hooks/useFetch';
import Pagination from '../Pagination';

const NewsItem = ({ id, date, title }) => {
  const navigate = useNavigate();

  return (
    <div
      className="news-item"
      onClick={() => navigate(`/news/${id}`)}
    >
      <div className="news-content">
        <div className="news-date">{date}</div>
        <div className="news-title">{title}</div>
      </div>
    </div>
  );
};

const NewsList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data } = useFetch(
    `/news?page=${currentPage}&pageSize=10`
  );

  const handlePageChange = () => {
    // todo 換頁
  };

  const location = useLocation();

  useEffect(() => {
    const page = new URLSearchParams(location.search).get(
      'page'
    );
    if (page) {
      setCurrentPage(page);
    } else {
      setCurrentPage(1);
    }
  }, []);

  if (!data) return null;

  return (
    <>
      <div className="bg-b">
        <div className="container">
          <div className="fz-30 page-title">
            LATEST NEWS
          </div>

          <>
            <div className="news">
              {data?.data?.map(news => (
                <NewsItem
                  key={news.id}
                  id={news.id}
                  title={news.title}
                  date={moment(news.start_datetime).format(
                    'YYYY/MM/DD'
                  )}
                />
              ))}
            </div>
            {data?.data?.length > 0 && (
              <Pagination
                totalPages={data?.total_page}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default NewsList;
