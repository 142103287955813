import { useParams } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import SimpleLayout from '../../components/customPage/SimpleLayout';

const News = () => {
  const { id } = useParams();
  const { data } = useFetch(`/news/${id}`);

  return (
    <>
      <SimpleLayout
        title={data?.title}
        content={data?.content}
      />
    </>
  );
};

export default News;
