import router from './contact';
import Layout from './components/Layout';
import { RouterProvider } from 'react-router-dom';
import { AuthProvider } from './context/UserContext';
import { RequestProvider } from './context/RequestContext';

import './assets/scss/form.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import 'sweetalert2/src/sweetalert2.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function App() {
  return (
    <RequestProvider>
      <AuthProvider>
        <RouterProvider router={router}>
          <Layout />
        </RouterProvider>
      </AuthProvider>
    </RequestProvider>
  );
}

export default App;
