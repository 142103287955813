import { useEffect, useRef, useState } from 'react';
import '../assets/scss/form.scss';

const FileThumbnail = ({ file }) => {
  const [fileObj, setFileObj] = useState(file);
  const ref = useRef(null);

  const handleDownloadFile = () => {
    if (file?.url && ref.current) {
      ref.current.href = file.url;
      ref.current.download = file.name;
      ref.current.click();
    }
  };

  useEffect(() => {
    if (file) {
      setFileObj(file);
    }
  }, [file]);

  return (
    <div className="file-download">
      <a
        ref={ref}
        className="download-icon"
        onClick={handleDownloadFile}
      >
        {(file?.url?.split('.').pop().includes('doc') ||
          file?.name?.split('.').pop().includes('doc')) && (
          <img
            src={`${process.env.PUBLIC_URL}/images/submission-icon-uploadword.svg`}
            alt="submission-icon-uploadword"
          />
        )}
        {(file?.url?.split('.').pop().includes('pdf') ||
          file?.name?.split('.').pop().includes('pdf')) && (
          <img
            src={`${process.env.PUBLIC_URL}/images/submission-icon-uploadpdf.svg`}
            alt="submission-icon-uploadword"
          />
        )}
        {(file?.name?.split('.').pop().includes('jpg') ||
          file?.name
            ?.split('.')
            .pop()
            .includes('jpeg')) && (
          <img
            src={`${process.env.PUBLIC_URL}/images/submission-icon-uploadpdf.svg`}
            alt="submission-icon-uploadword"
          />
        )}
        {file?.name?.split('.').pop().includes('png') && (
          <img
            src={`${process.env.PUBLIC_URL}/images/submission-icon-uploadpng.svg`}
            alt="submission-icon-uploadword"
          />
        )}
        <div>
          <span className="fz-12">{fileObj?.name}</span>
          <span className="fz-10 color-gray">{`檔案大小 ${((fileObj?.filesize || fileObj?.size) / 1024 / 1024).toFixed(2) + 'MB' || ''}`}</span>
        </div>
        {file?.url && (
          <img
            src={`${process.env.PUBLIC_URL}/images/submission-icon-download.svg`}
            alt="submission-icon-download"
          />
        )}
      </a>
    </div>
  );
};

export default FileThumbnail;
