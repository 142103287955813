import { createBrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Layout from './components/Layout';
import Login from './pages/Login';
import News from './components/news/News';
import NewsList from './components/news/NewsList';
import Register from './pages/Register';
import VerifyEmailToken from './pages/VerifyEmailToken';
import SubmissionList from './pages/submission/SubmissionList';
import SubmissionCreate from './pages/submission/SubmissionCreate';
import SubmissionEdit from './pages/submission/SubmissionEdit';
import ForgetPassword from './pages/ForgetPassword';
import CustomPage from './pages/CustomPage';
import SubmissionPreview from './pages/submission/SubmissionPreview';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'about',
        element: <About />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'register',
        element: <Register />,
      },
      {
        path: '/news',
        element: <NewsList />,
      },
      {
        path: 'news/:id',
        element: <News />,
      },
      {
        path: 'verify-email-token/:token',
        element: <VerifyEmailToken />,
      },
      {
        path: 'forget-password',
        element: <ForgetPassword />,
      },
      {
        path: 'submission',
        element: <SubmissionList />,
      },
      {
        path: 'submission/create',
        element: <SubmissionCreate />,
      },
      {
        path: 'submission/:id',
        element: <SubmissionEdit />,
      },
      {
        path: 'page/:id',
        element: <CustomPage />,
      },
    ],
  },
  {
    path: 'submission/preview/:id',
    element: <SubmissionPreview />,
  },
]);

export default router;
