import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRequest } from '../context/RequestContext';
import QuickLinks from '../components/home/QuickLinks';
import KeyDates from '../components/home/KeyDates';
import News from '../components/home/News';
import Countdown from '../components/countdown/Countdown';
import Footer from '../components/footer/Footer';
import WelcomeMessage from '../components/home/WelcomeMessage';
import Sponsors from '../components/home/Sponsors';
import useFetch from '../hooks/useFetch';

const Home = () => {
  const { data: homePageData } = useFetch('/page/home', {
    method: 'GET',
  });

  // const fetchData = async () => {
  //   const result = await request('/custom/v1/pages/home', {
  //     method: 'GET',
  //   });

  //   if (result.success) {
  //     const { data } = result;
  //     // console.log(data);
  //     setData({
  //       quickLinks: data.quick_links,
  //       news: data.news,
  //       keyDates: data.key_dates,
  //     });
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  return (
    <>
      <div className="bg">
        <section className="container home-info mt-40">
          <QuickLinks
            quickLinks={homePageData?.quickLink}
          />
          <News news={homePageData?.news} />
          <KeyDates keyDates={homePageData?.keyDate} />
        </section>
        <WelcomeMessage />
        {/* <div className="home-video">
        <img
          className="video"
          src={`${process.env.PUBLIC_URL}/images/home-video.png`}
          alt="video"
        />
      </div> */}
        <Sponsors />
      </div>
      <Footer />
    </>
  );
};

export default Home;
