import './simpleLayout.scss';

const SimpleLayout = ({ title, content }) => {
  return (
    <div className="page-container">
      <div className="container mb-40">
        <div className="page-title fz-30">{title}</div>
        <div
          className="page-content-box"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
    </div>
  );
};

export default SimpleLayout;
