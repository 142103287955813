import { useState, useEffect } from 'react';
import Countdown from '../countdown/Countdown';
import './banner.scss';

const Banner = () => {
  const [styles, setStyles] = useState({});
  const updateStyles = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth > 1480 && screenWidth <= 1920) {
      setStyles({
        marginLeft: `${410 - (1920 - screenWidth) * 0.5}px`,
        maxWidth: `${50 + (1920 - screenWidth) * 0.03}%`,
      });
    } else if (screenWidth > 1920 && screenWidth <= 2240) {
      setStyles({
        marginLeft: `${410 - (1920 - screenWidth) * 0.2}px`,
      });
    } else if (screenWidth > 2240) {
      setStyles({
        marginLeft: `${420 - (1920 - screenWidth) * 0.2}px`,
      });
    }
  };

  useEffect(() => {
    updateStyles();
    window.addEventListener('resize', updateStyles);
    return () =>
      window.removeEventListener('resize', updateStyles);
  }, []);

  return (
    <section className="home-banner">
      <p
        className="home-banner-title"
        style={{
          maxWidth: styles.maxWidth,
          marginLeft: styles.marginLeft,
        }}
      >
        18th Asian Pacific Society of Atherosclerosis And
        Vascular Diseases Congress 2025
      </p>
      <Countdown />
    </section>
  );
};

export default Banner;
